import React, { } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCropFrame } from "../../redux/appSlice";
import { setCropFramePosition, stopEventBubbling } from "../../js/utils";
import { Tooltip } from "@mui/material";

const CropFrameInput = () => {
  const dispatch = useDispatch();
  const { sCropFrame, ratiosList } = useSelector((state) => state);

  function toggleFrame() {
    dispatch(setCropFrame({isOn: !sCropFrame.isOn}));
  }

  function setCropFrameRatio(index) {
    // if(sCropClips.length){
    //   const width = clipWidth(sCropClips[0]);
    //   sCropClips.every(clip => clipWidth(clip) == width);

    //   return;
    // }
    const ratioWidths = [100, 56.25, 42.19, 31.64];
    dispatch(setCropFrame({ isOn: true, ratio: index, x1: 0, x2:  ratioWidths[index]}));
    setCropFramePosition(0);
  }

  return (
    <div className="crop_frame_inputs">
      <p className="flex align-center">
        Crop
        <label className="inline-flex items-center cursor-pointer ml-6">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={sCropFrame.isOn}
            onChange={toggleFrame}
            onKeyDown={stopEventBubbling}
          />
          <Tooltip title="Show/Hide Frame" placement="right" arrow sx={{pointerEvents: 'none'}}>
            <span className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></span>
          </Tooltip>
        </label>
        <span className="ml-6">Keep crop ratio same for all clips.</span>
      </p>

      <div className="mt-4 flex gap-10">
        {ratiosList.map((ratio, i) => (
          <label
            key={ratio}
            htmlFor={"ratio-" + ratio}
            className="select-none cursor-pointer relative"
          >
            <input
              type="radio"
              name="ratio"
              id={"ratio-" + ratio}
              className="peer absolute top-1 left-1"
              value={ratiosList[i]}
              checked={ratio === ratiosList[sCropFrame.ratio]}
              onChange={() => setCropFrameRatio(i)}
              onKeyDown={stopEventBubbling}
            />
            <div className={`py-2 px-10 bg-blue-500/50 peer-checked:bg-blue-600/80`}>
              {ratio.replace('/', ':')}
            </div>
          </label>
        ))}
      </div>
    </div>
  );
};

export default CropFrameInput;
