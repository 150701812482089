import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import AppBody from "./AppBody";
import Header from "./modules/Header";
import ClipAndMergePage from "./pages/ClipAndMergePage";
import { integrateYTApi } from "./js/utils";
import LandingPage from "./pages/LandingPage";
import Footer from "./modules/Footer";

const App = () => {

  useEffect(() => { integrateYTApi() }, []);

  return (
    <BrowserRouter>
      <Header />
      <Suspense fallback={<h3 className="center_pad">Loading...</h3>}>
        <Routes>
          <Route path="/" element={<AppBody />}>
            <Route index element={ <LandingPage />}/>
            <Route path="/clip" element={ <ClipAndMergePage />}/>
            <Route
              path="*"
              element={<h3 className="center_pad">404 Not Found</h3>}
            />
          </Route>
        </Routes>
      </Suspense>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
