import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import SidePanel from "../modules/SidePanel";
import VideoViewer from "../modules/VideoViewer";

import { getVideoId, handleKeyDown, handleMouseUp } from "../js/utils";
import { setActiveVideo } from "../redux/appSlice";

const ClipAndMergePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const searchUrl = new URL(window.location.href).search.slice(1) || null;

    const ytVideoId = getVideoId(searchUrl);

    if (ytVideoId) {
      const ytURL = `https://www.youtube.com/watch?v=${ytVideoId}`;
      dispatch(setActiveVideo({ url: ytURL, id: ytVideoId }));
    }

    // eslint-disable-next-line
  }, []);


  useEffect(()=>{
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mouseup", handleMouseUp);
    
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mouseup", handleMouseUp);
    }
  })

  return (
    <div className="stage wrapper">
      <main>
        <VideoViewer />
      </main>
      <hr />
      <aside>
        <SidePanel />
      </aside>
    </div>
  );
};

export default ClipAndMergePage;
