import React from "react";
import { useLocation } from "react-router-dom";
import { toast } from "../js/utils";

const Footer = () => {
  const location = useLocation();
  const isClipRoute = location.pathname === "/clip";

  function copyToClipboard(e){
    e.preventDefault();
    toast("Copied to clipboard", 3);
    navigator.clipboard.writeText(e.target.innerText);
  }

  return isClipRoute ? (
    <></>
  ) : (
    <footer>
      <div className="wrapper">
        <p onClick={copyToClipboard} title="click to copy">contact@clipcut.tech</p>
        <p>ClipCut Copyright © 2024 ClipCut. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
