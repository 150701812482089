export class Clipper {
    #timeRange = [undefined, undefined, "0, 0, 100, 100"];
    constructor(...values) {
      this.setTimeRange(...values);
  
      return this.#timeRange;
    }
  
    setTimeRange(...values) {
      if (!values.length && values.length > 4) {
        console.error("Invalid parameters length");
      }
  
      this.#timeRange[0] = values[0];
      this.#timeRange[1] = values[1];
      this.#timeRange[2] = `${values[2]}, 0, ${values[3]}, 100`;
    }
  
    startTime(value) {
      if (typeof value == "number") this.#timeRange[0] = value;
      else {
        console.error("Expected start time to be number but got ", typeof value);
      }
  
      return this;
    }
  
    endTime(value) {
      if (typeof value == "number") this.#timeRange[1] = value;
      else {
        console.error("Expected End time to be number but got ", typeof value);
      }
  
      return this;
    }
  
    setCoordinate(x1, x2) {
      this.#timeRange[3] = `${x1}, 0, ${x2}, 100`;
    }

    parse(){
      alert(this.#timeRange);
      // this.parseClip(this.#timeRange);
    }

    get value() {
      return this.#timeRange;
    }


  }