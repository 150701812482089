/* eslint-disable eqeqeq */

import React, { useState } from "react";
import { useSelector } from "react-redux";

const ClipDownloaderModal = () => {
  const { loadingState, clipOptions, sCropClips } = useSelector((state) => state);
  const { isSubsNeeded } = clipOptions;

  const dowloadingProcess = [
    { t: 5, text: "Fetching video from the servers...", show: true },
    { t: 15, text: "Clipping your video...", show: true },
    { t: 10, text: "Adjusting frames...", show: true },
    { t: 15, text: "Merging the clips...", show: sCropClips.length > 1 },
    { t: 15, text: "Generating Subtitles...", show: isSubsNeeded },
    { t: 30, text: "Compiling the video...", show: true },
  ].filter(step => step.show);

  const [loadingText, setLoadingText] = useState(dowloadingProcess[0].text);

  useState(() => {
    let i = 0;

    const updateLoadingText = () => {
      if (i < dowloadingProcess.length) {
        const { text, t } = dowloadingProcess[i];
        setLoadingText(text);
        i++;
        setTimeout(updateLoadingText, t * 1000);
      }
    };

    updateLoadingText();

    // Cleanup timeout on unmount
    return () => clearTimeout(updateLoadingText);
  }, []);

  return (
    <div className="modal">
      <div className="modal_container">
        <p className="text-white text-center mt-20 mb-6 text-2xl font-bold">{loadingState == 1 ? loadingText : 'Downloading'} </p>
        <div className="loader m-auto"></div>
      </div>
    </div>
  );
};

export default ClipDownloaderModal;
