/* eslint-disable eqeqeq */

import React, { useEffect } from "react";
import { $, stopEventBubbling, updateValidation } from "../../js/utils";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { set, updateClipRange, updateCropClips } from "../../redux/appSlice";

const TimeInput = () => {
  const dispatch = useDispatch();
  const { startInput, endInput, sClip, sVideo } = useSelector((state) => state);

  function addStartEndTimes(e) {
    dispatch(updateCropClips());
    e.target.blur();
  }

  function onStartTimeChange() {
    if(!startInput.value) return;

    const value = Number(startInput.value);
    const { startTime, endTime } = sClip;

    if (startInput.getAttribute("min") == value) return;

    if (!value) {
      updateValidation(0, endTime);
      dispatch(updateClipRange({ clipStart: 0, clipEnd: endTime }));
      return;
    }

    if (value != startTime && value < endTime - 0.01 && value >= 0) {
      updateValidation(value, endTime);
    }

    if (value >= endTime) return;

    dispatch(updateClipRange({ clipStart: value, clipEnd: endTime }));
  }

  function onEndTimeChange() {
    if(!endInput.value) return;

    const value = Number(endInput.value);
    const { startTime, endTime } = sClip;
    const { duration } = sVideo;

    if (endInput.getAttribute("max") == value) return;

    if (!value) {
      updateValidation(startTime, endTime);
      dispatch(updateClipRange({ clipStart: startTime, clipEnd: duration }));
      return;
    }

    if (
      value != endTime &&
      value > startTime + 0.01 &&
      value <= duration
    ) {
      updateValidation(startTime, value);
    }

    if (value <= startTime || value >= duration) return;

    dispatch(updateClipRange({ clipStart: startTime, clipEnd: value }));
  }

  function onKeyDown(event) {
    stopEventBubbling(event);
    const controlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End', 'Tab'];
    if (controlKeys.includes(event.key) || !/[^\d.]/.test(event.key)) {
      return;
    }

    event.preventDefault();
  }

  useEffect(() => {
    dispatch(set({startInput: $("#start_input"),endInput: $("#end_input")}));
  }, []);

  useEffect(() => {
    if(startInput) {
      startInput.value = sClip.startTime;
      // startInput.selectionStart = startInput.selectionEnd = startInput.value.length;
    }
  }, [sClip.startTime]);

  useEffect(() => {
    if(endInput) endInput.value = sClip.endTime
  }, [sClip.endTime]);

  return (
    <div className="range_input">
      <div className="time_input">
        <div className="input_field">
          <label htmlFor="start_input">From:</label>
          <input
            id="start_input"
            type="text" autoComplete="off"
            min="0"
            max="9"
            step="0.01"
            onKeyDown={onKeyDown}
            onInput={onStartTimeChange}
          />
        </div>

        <div className="input_field">
          <label htmlFor="end_input">To :</label>
          <input id="end_input" type="text"
            autoComplete="off"
            min="1"
            max="10"
            step="0.01"
            onKeyDown={onKeyDown}
            onInput={onEndTimeChange}
          />
        </div>
      </div>

      <Button
        className={"add_btn"}
        variant="contained"
        children="Add ⮐"
        onClick={addStartEndTimes}
      />
    </div>
  );
};

export default TimeInput;
