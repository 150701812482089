import React, { useEffect, useRef } from "react";
import { getVideoId } from "../js/utils";

const LandingPage = () => {
  const urlInputRef = useRef();
  const videoRef = useRef();

  function handleUrlInput(e) {
    e.preventDefault();
    const url = urlInputRef.current.value;
    const ytVideoId = getVideoId(url);

    if (!ytVideoId) return;

    const newURL = `https://www.youtube.com/watch?v=${ytVideoId}`;

    urlInputRef.current.value = "";
    window.open(`/clip?${newURL}`, "_self");
  }

  const featuresList = [
    {
      className: 'merging',
      title: "Multiple Clips, One Video",
      description:
        "Merge multiple clips from the same video to create a custom compilation.",
      image: "merging.png",
      color: "#ff00c8"
    },
    {
      className: 'clipping',
      title: "Effortless Clipping",
      description:
        "Choose your start and end times with pinpoint accuracy, and ClipCut does the rest. It's that simple!",
      image: "clipping.png",
      color: "#00ffd5"
    },
    {
      className: 'cropping',
      title: "Smart Cropping",
      description:
        "Resize your clips to any aspect ratio you need, perfect for Reels ot TikToks",
      image: "cropping.png",
      color: "#fffb00"
    },
    {
      className: 'quality',
      title: "High-Quality Downloads",
      description:
        "ClipCut maintains the original video quality, so your downloads look just as good as the originals.",
      image: "quality.png",
      color: "#7a00ff"
    },
    {
      className: 'subtitles',
      title: "AI-Powered Subtitles",
      description:
        "Generate subtitles for your videos. Translate into other languages for global reach!",
      image: "subtitles.png",
      color: "#002bff"
    },
  ];

  function seekVideo(seconds) {
    const video = videoRef.current;
    const newTime = video.currentTime + seconds;
    if (newTime >= 0 && newTime <= video.duration) {
      video.currentTime = newTime;
    } else if (newTime < 0) {
      video.currentTime = 0;
    } else {
      video.currentTime = video.duration;
    }
  }

  function handleKeyDown(event) {
    switch (event.key) {
      case "ArrowRight":
        seekVideo(10);
        break;
      case "ArrowLeft":
        seekVideo(-10);
        break;
      default:
        break;
    }
  }

  useEffect(()=>{
    document.addEventListener("keydown", handleKeyDown);
    
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    }
  })
  

  return (
    <div className="landing_page wrapper">
      <section className="hero">
        <h1 className="">
          Paste Link. Cut Clips. Download.
          <br />
        </h1>
        <p className="sub_heading">It's that easy!</p>

        <span className="bg-oval" role="presentation"></span>
        <span className="bg-oval oval2" role="presentation"></span>
      </section>

      <section className="IO">
        <div className="input">
          <form onSubmit={handleUrlInput}>
            <div className="url">
              <img src="./assets/images/input-icon.svg" alt="input icon" />
              <input
                ref={urlInputRef}
                type="text"
                placeholder="https://www.youtube.com/watch?v=Glm..."
                required
                // pattern="^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?.*v=|embed\/|v\/|u\/\w\/|playlist\?.*list=)|youtu\.be\/)([\w-]{11})(?:[\?&].*|)$"
              />
              {/* <!-- RegEx LINK : https://pastebin.com/Z31jxTXP --> */}
              <button className="py-1 px-2 font-bold" type="submit">⮐</button>
            </div>
          </form>
        </div>
      </section>

      <section className="app_preview wrapper">
        <video
          ref={videoRef}
          // src="./assets/images/features/clipcutpreview.mp4"
          src="./assets/images/features/clipcutpreview.webm"
          autoPlay
          loop
          muted
          controlsList="nodownload"
          playsInline
          preload="auto"
        >
          Your browser does not support the video tag.
        </video>
      </section>
      <p className="flex justify-center gap-4">
        <div className="cursor-pointer" onClick={()=>seekVideo(-10)}>⬅</div>
        <div className="cursor-pointer rotate-180" onClick={()=>seekVideo(10)}>⬅</div>
      </p>

      <section className="video_count">
        <h2 className="text-center mt-[100px] text-3xl font-semibold text-slate-400">
          Total videos created so far
        </h2>
        <p className="text-center text-6xl font-semibold mt-2 mb-[150px]">
          <span>1037</span>
        </p>
      </section>

      <section className="features_list">
        <h2 className="text-center mb-20 text-4xl text-slate-200">
          Features Made for Easy Video Clipping
        </h2>
        <div className="features">
          {featuresList.map(({ className, title, description, image, color }) => (
            <div key={className} className={"feature " + className} style={{ '--color' : color}}>
              <div className="text_content">
                  <h4>{title}</h4>
                  <p>{description}</p>
              </div>
              <div className="image_content">
                  <img src={`./assets/images/features/${image}`} alt={title} />
              </div>
            </div>
          ))}
        </div>
      </section>

      <div className="w-fit mx-auto mt-40 mb-10">
        <a href="/clip">
          <button className="clip_btn feedback_button">Clip Now</button>
        </a>
      </div>
    </div>
  );
};

export default LandingPage;