import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { $, getVideoId, stopEventBubbling } from "../js/utils";
import { setActiveVideo } from "../redux/appSlice";
import { useLocation, useSearchParams } from "react-router-dom";
import { Tooltip } from "@mui/material";

const Header = () => {
  const [, setSearchParams] = useSearchParams();
  
  const location = useLocation();
  const isClipRoute = location.pathname === '/clip';

  const dispatch = useDispatch();
  const { sVideo } = useSelector((state) => state);

  const [urlString, setUrlString] = useState(sVideo.url || "");

  function handleUrlChange(event) {
    const inputEl = event.target;

    setUrlString(inputEl.value);

    const ytVideoId = getVideoId(inputEl.value);

    if (!ytVideoId) return;

    const newURL = `https://www.youtube.com/watch?v=${ytVideoId}`;

    dispatch(setActiveVideo({ url: newURL, id: ytVideoId }));

    inputEl.blur();
  }
  useEffect(() => {
    if (sVideo.url) {
      setUrlString(sVideo.url);
      setSearchParams(`?${sVideo.url}`)
    }
    // eslint-disable-next-line
  }, [sVideo.url]);

  useEffect(() => {}, [urlString]);

  return (
    <header>
      <div className="wrapper">
        <h1>
          <a href="/" className="no-underline" title="Home page">ClipCut</a>
          {isClipRoute && <form onSubmit={stopEventBubbling}>
            <input
              id="url"
              type="text"
              autoComplete="off"
              value={urlString}
              onInput={handleUrlChange}
              onKeyDown={stopEventBubbling}
              placeholder="https://www.youtube.com/watch?v=Glm..."
            />
            <button type="reset" onClick={() => {
              setUrlString('');
              $('#url').focus()
            }}><span>+</span></button>
          </form>}
        </h1>

        <Tooltip title="Feedback and Suggestions" placement="bottom" sx={{m: 1, pointerEvents: 'none'}}>
          <a href="https://forms.gle/tRCq5JF6UzTfB6mH9" target="_blank" rel="noreferrer"><button className="feedback_button"><span>✨</span> Innovate Something</button></a>
        </Tooltip>
      </div>
    </header>
  );
};

export default Header;
